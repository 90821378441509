<template>
  <v-app>
    <core-app-bar />

    <core-view />

    <core-footer />
  </v-app>
</template>

<script>
import { getUserProfile } from "./utils/ServerCallsUtils";
import { mapMutations } from "vuex";
import { CARNAVAL_SERVICE_NAME } from "./utils/Constants";

// Stores the service name if the website was accessed from CAS
var serviceName = null;
// Stores the service url if the website was accessed from CAS
var serviceUrl = null;

function is_carnaval_service(serviceName) {
  return CARNAVAL_SERVICE_NAME == serviceName;
}

function changeTitleIfCarnavalApp() {
  if (is_carnaval_service(serviceName)) document.title = "FacileWiFi Login";
}

export default {
  name: "App",

  methods: {
    ...mapMutations(["setUserProfile"]),
    ...mapMutations(["setServiceName"]),
    ...mapMutations(["setServiceUrl"]),
  },

  async beforeCreate() {
    // Get the service name if the website was accessed from CAS
    serviceName = null != this.$route.query ? this.$route.query.service : null;
    
    // Get the service url if the website was accessed from CAS
    serviceUrl = null != this.$route.query ? this.$route.query.url : null;
    console.log(serviceUrl);
    if (null != serviceUrl && serviceUrl.slice(-2) == ".*") {
      serviceUrl = serviceUrl.substring(0, serviceUrl.length - 2); // Remove the last two characters (.*)
      serviceUrl = serviceUrl + "/"; // Add the trailing slash
    }

    changeTitleIfCarnavalApp();

    // Wait until reCAPTCHA has been loaded.
    await this.$recaptchaLoaded();
    const recaptcha = this.$recaptchaInstance;
    if (null != recaptcha) {
      // Hide reCAPTCHA badge:
      recaptcha.hideBadge();
      console.log("reCAPTCHA is hidden");
    }
  },

  async created() {
    this.setServiceName(serviceName);
    this.setServiceUrl(serviceUrl);

    const user_profile_recieved = await getUserProfile();
    this.setUserProfile(user_profile_recieved);
  },

  components: {
    CoreFooter: () => import("@/components/core/Footer"),
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreView: () => import("@/components/core/View"),
  },

  watch: {
    $route: async function() {
      // Double $vuetify.goTo(0) is
      // a hack to fix vuetify goto bug.
      // Delete the line above if it is fixed in the later versions of vuetify
      await this.$vuetify.goTo(0, { duration: 0 });
      this.$vuetify.goTo(0);

      changeTitleIfCarnavalApp();
    },
  },
};
</script>

<style>
@import "./assets/styles/styles.css";
</style>
